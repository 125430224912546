import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { EventInterface } from "../../../store/reducers/events_list-reducer";
import { useQueryClient } from 'react-query';
import { useIntersectionObserver } from "../../../hooks/useIntersectionObserver";
import "./EventsSection.css"

// Компонент EventSector
const EventSector: React.FC<{ event: EventInterface }> = ({ event }) => {
  const { t, i18n } = useTranslation();
  const [, setDate] = useState<null | Date>(null);
  const containerRef = useRef(null);
  const { isVisible } = useIntersectionObserver(containerRef, 0.1);

  const displayDateAndTimezone = () => {
    const currentDate = new Date();
    setDate(currentDate);
  };

  const handleTimeDifference = (
    startDate: Date | string | null | undefined,
    endDate: Date | string | null | undefined
  ) => {
    const currentDate = new Date();
    const parsedStartDate = startDate ? new Date(startDate) : null;
    const parsedEndDate = endDate ? new Date(endDate) : null;
  
    // Если нет startDate
    if (!parsedStartDate)
      return <span>{t("EventsSection.startDateUnknown")}</span>;
  
    let difference = parsedStartDate.getTime() - currentDate.getTime();
    let info = t("EventsSection.eventStarts");
  
    // Если событие уже началось
    if (difference <= 0) {
      // Если нет endDate
      if (!parsedEndDate)
        return <span>{t("EventsSection.endDateUnknown")}</span>;
  
      difference = parsedEndDate.getTime() - currentDate.getTime();
      info = t("EventsSection.eventEnds");
    }
  
    const millisecondsInOneDay = 86400000;
    const deltaDays = Math.trunc(difference / millisecondsInOneDay);
    const deltaHours = Math.trunc((difference % millisecondsInOneDay) / 3600000);
    const deltaMinutes = Math.trunc((difference % 3600000) / 60000);
    const deltaSeconds = Math.trunc((difference % 60000) / 1000);
  
    // Если событие закончилось
    if (difference < 0) return <span>{t("EventsSection.eventEnded")}</span>;
  
    return (
      <span>
        {info}
        <br />
        {deltaDays + " " + t("EventsSection.d")}{" "}
        {deltaHours + " " + t("EventsSection.h")}{" "}
        {deltaMinutes + " " + t("EventsSection.m")}{" "}
        {deltaSeconds + " " + t("EventsSection.s")}
      </span>
    );
  };    
  
  useEffect(() => {
    const timeInterval = setInterval(displayDateAndTimezone, 1000);
    return () => {
      clearInterval(timeInterval);
    };
  }, []);

  return (
      <article
        ref={containerRef}
        className={`event-sector ${isVisible ? "event-sector--animated" : ""}`}
      >
        {handleTimeDifference(event.startDate, event.endDate)}
        {
          i18n.language == "ru" && <a href={event.link} target="_blank" rel="noopener noreferrer">
          <div className="event-image-container-ru">
              <img src={event.imgSrc} alt={event.nameRu} />
          </div>
          </a>
      }
      {
          i18n.language == "en" && <div className="event-image-container-en">
              <img src={event.imgSrc} alt={event.nameEn} />
          </div>
          
      }
      {i18n.language == "ru" && (
        <p className="event-description">{t("EventsSection.description")}</p>
      )}
      </article>
  );
};
  
  export const EventsSection: React.FC = () => {
    const events = useQueryClient().getQueryData<EventInterface[]>('events_list') || [];
    const { t } = useTranslation();
    return (
      <section className="events-section">
        <h2>{t("EventsSection.header")}</h2>
        {events.map((event, index) => (
          <EventSector key={index} event={event} />
        ))}
      </section>
    );
  };