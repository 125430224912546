import { useQuery, QueryClient } from "react-query";
import { statusesKeys, normalizeKeys } from "../constants/statusesKeys";
import { supabase } from './supabaseClient';
import { getValidatedDataSupabase } from "../constants/validations";

const CACHE_KEY = 'statuses_cache';
const CACHE_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds

const getDataFromCache = () => {
  const cachedData = localStorage.getItem(CACHE_KEY);
  if (cachedData) {
    const { data, timestamp } = JSON.parse(cachedData);
    if (Date.now() - timestamp < CACHE_TIME) {
      return data;
    }
  }
  return null;
};

const setDataToCache = (data: any) => {
  const cacheData = {
    data,
    timestamp: Date.now(),
  };
  localStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));
};

const useFetchStatusesAction = async () => {
  const cachedData = getDataFromCache();
  if (cachedData) {
    return cachedData;
  }

  try {
    const { data: sinner_statuses, error: sinnerError } = await supabase
    .from('sinner_statuses_db')
    .select('*')
    .order('id', { ascending: true })
    
    const { data: anomaly_statuses, error: anomalyError } = await supabase
    .from('anomaly_statuses_db')
    .select('*')
    .order('id', { ascending: true })

    if (sinnerError || anomalyError) {
      console.error(sinnerError || anomalyError);
    } else {
      const combined_statuses = [...(sinner_statuses || []), ...(anomaly_statuses || [])];
    
      const result = getValidatedDataSupabase(combined_statuses, statusesKeys, normalizeKeys);
      setDataToCache(result);
      return result;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const useFetchStatuses = () => {
  return useQuery("statuses", useFetchStatusesAction, {
    staleTime: CACHE_TIME,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    retry: 8,
    retryDelay: (attempt) =>
      Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
    onError: (error) => {
      console.error("Error fetching statuses:", error);
    },
  });
};

// Create a custom QueryClient to handle cache persistence
export const createQueryClient = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: CACHE_TIME,
        cacheTime: Infinity,
      },
    },
  });

  // Hydrate the cache from localStorage on client-side
  if (typeof window !== 'undefined') {
    const cachedData = getDataFromCache();
    if (cachedData) {
      queryClient.setQueryData("statuses", cachedData);
    }
  }

  return queryClient;
};