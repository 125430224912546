import { useQuery, QueryClient } from "react-query";
import { newsListKeys, normalizeKeys } from "../constants/newsListKeys";
import { supabase } from './supabaseClient';
import { getValidatedDataSupabase } from "../constants/validations";

const CACHE_KEY = 'news_list_cache';
const CACHE_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds

const getDataFromCache = () => {
  const cachedData = localStorage.getItem(CACHE_KEY);
  if (cachedData) {
    const { data, timestamp } = JSON.parse(cachedData);
    if (Date.now() - timestamp < CACHE_TIME) {
      return data;
    }
  }
  return null;
};

const setDataToCache = (data: any) => {
  const cacheData = {
    data,
    timestamp: Date.now(),
  };
  localStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));
};

const useFetchNewsListAction = async () => {
  const cachedData = getDataFromCache();
  if (cachedData) {
    return cachedData;
  }

  try {
    const { data: news, error } = await supabase
    .from('news_list_db')
    .select('*')
    .order('startDate', { ascending: false })
            
    if (error) {
      throw error;
    }

    if (!news) {
      throw new Error('No data received');
    }

    const result = getValidatedDataSupabase(news, newsListKeys, normalizeKeys);

    setDataToCache(result);
    return result;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const useFetchNewsList = () => {
  return useQuery("news_list", useFetchNewsListAction, {
    staleTime: CACHE_TIME,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    retry: 8,
    retryDelay: (attempt) =>
      Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
    onError: (error) => {
      console.error("Error fetching ego:", error);
    },
  });
};

// Create a custom QueryClient to handle cache persistence
export const createQueryClient = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: CACHE_TIME,
        cacheTime: Infinity,
      },
    },
  });

  // Hydrate the cache from localStorage on client-side
  if (typeof window !== 'undefined') {
    const cachedData = getDataFromCache();
    if (cachedData) {
      queryClient.setQueryData("news_list", cachedData);
    }
  }

  return queryClient;
};