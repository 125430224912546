import React, {useRef} from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useIntersectionObserver } from "../../../hooks/useIntersectionObserver";
import { EGOInterface } from "../../../store/reducers/ego-reducer";
import { IdentityInterface } from "../../../store/reducers/ids-reducer";
import { ItemEntity } from "../../item-entity/ItemEntity";
import "./EntitySection.css"
interface EntitySectionBarProps {
    section:{
        date: Date;
        data: Array<IdentityInterface | EGOInterface>;
    }
}
export const EntitySection:React.FC = () => {
    const ids = useQueryClient().getQueryData("identities") as IdentityInterface[]|null;
    const ego = useQueryClient().getQueryData("ego") as EGOInterface[]|null;
    const {t} = useTranslation();
    function dateToExcel(jsDate: string | number | Date): number {
        let date: Date;
        // Преобразуем входные данные в объект Date
        if (typeof jsDate === 'string' || typeof jsDate === 'number') {
            date = new Date(jsDate);
        } else if (jsDate instanceof Date) {
            date = jsDate;
        } else {
            return 0; // или другое значение по умолчанию
        }
        // Проверяем, является ли дата валидной
        if (isNaN(date.getTime())) {
            return 0; // или другое значение по умолчанию
        }
        const excelStartDate = new Date(1899, 11, 30);
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const daysDifference = (date.getTime() - excelStartDate.getTime()) / millisecondsPerDay;
        return Math.round(daysDifference);
    }
      function formatDate(date: string | number | Date): string {
        let d: Date;
        if (typeof date === 'string' || typeof date === 'number') {
          d = new Date(date);
        } else if (date instanceof Date) {
          d = date;
        } else {
          return 'Invalid Date';
        }
        if (isNaN(d.getTime())) {
          return 'Invalid Date';
        }
        return `${String(d.getDate()).padStart(2, '0')}.${String(d.getMonth() + 1).padStart(2, '0')}.${d.getFullYear()}`;
      }
    const findNLatestDates = (ids:IdentityInterface[], egos:EGOInterface[] , N:number) =>{
        const allData: Array<IdentityInterface | EGOInterface> = [...ids,...egos];
        allData.sort((a, b) =>{
            return dateToExcel(b.releaseDate) - dateToExcel(a.releaseDate);
        } );
        const result:Array<{date:Date,data:Array<IdentityInterface|EGOInterface>}> = [] ;
        for (let i = 0; i < allData.length; i++) {
            const currentData = allData[i];
            const date = currentData.releaseDate;
            if(!result.length){
                result.push({date,data:[currentData]});
                continue;
            } 

            let isPushed = false;
            for(let r = 0 ; r <result.length;r++){
                if(result[r].date === date){
                    result[r].data.push(currentData);
                    isPushed = true;
                    break;
                } 
            }
            if(!isPushed && result.length < N){
                result.push({date,data:[currentData]});
                continue;
            }
            if(!isPushed && result.length === N) break;
            
        }
        return result;
    }
    const EntitySectionBar:React.FC<EntitySectionBarProps> = ({section}) => {
        const containerRef = useRef(null);
        const {isVisible} = useIntersectionObserver(containerRef,0.1);
        return <article ref={containerRef} className={`entity-section-bar ${isVisible && "entity-section-bar--animated"}`}>
        <div className="release-date"> 
            <span className="date"> {formatDate(section.date)} </span>
            <hr></hr>
        </div>
        <div className="entities-list"> 
            {   
            section.data.map((entity)=>{
                return <ItemEntity key={entity.imgUrl} entity={entity} /> ;
            })
            }
        </div>
        
    </article>
    }
    
    return <section className="entity-section"> 
        <h2>{t('EntitySection.new')}</h2>
        { ids && ego && findNLatestDates(ids,ego,10).map((section,index)=>{
                return <EntitySectionBar key={index} section={section}/>
            })
        }
    </section>
}