import { useQuery, QueryClient } from "react-query";
import { egoKeys, normalizeKeys } from "../constants/egoKeys";
import { supabase } from './supabaseClient';
import { getValidatedDataSupabase } from "../constants/validations";

const CACHE_KEY = 'egos_cache';
const CACHE_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds

const getDataFromCache = () => {
  const cachedData = localStorage.getItem(CACHE_KEY);
  if (cachedData) {
    const { data, timestamp } = JSON.parse(cachedData);
    if (Date.now() - timestamp < CACHE_TIME) {
      return data;
    }
  }
  return null;
};

const setDataToCache = (data: any) => {
  const cacheData = {
    data,
    timestamp: Date.now(),
  };
  localStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));
};

const useFetchEgosAction = async () => {
  const cachedData = getDataFromCache();
  if (cachedData) {
    return cachedData;
  }

  try {
    const { data: egos, error } = await supabase
    .from('egos_db')
    .select('*')
    .order('sinner', { ascending: true })
    .order('rarity', { ascending: true })
    .order('releaseDate', { ascending: true })
    .order('imgUrl-id', { ascending: true })
            
    if (error) {
      throw error;
    }

    if (!egos) {
      throw new Error('No data received');
    }

    const result = getValidatedDataSupabase(egos, egoKeys, normalizeKeys);
    setDataToCache(result);
    return result;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const useFetchEgos = () => {
  return useQuery("ego", useFetchEgosAction, {
    staleTime: CACHE_TIME,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    retry: 8,
    retryDelay: (attempt) =>
      Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
    onError: (error) => {
      console.error("Error fetching ego:", error);
    },
  });
};

// Create a custom QueryClient to handle cache persistence
export const createQueryClient = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: CACHE_TIME,
        cacheTime: Infinity,
      },
    },
  });

  // Hydrate the cache from localStorage on client-side
  if (typeof window !== 'undefined') {
    const cachedData = getDataFromCache();
    if (cachedData) {
      queryClient.setQueryData("egos", cachedData);
    }
  }

  return queryClient;
};