import { validationToString, validationToStringOrNull } from "./validations";

const statusesKeys = [
    { key: 'id' ,validation:validationToString},
    { key: 'nameRU' ,validation:validationToString},    { key: 'descriptionRU',validation:validationToString},
    { key: 'unit' ,validation:validationToString},
    { key: 'nameEN' ,validation:validationToString},    { key: 'descriptionEN',validation:validationToString},
    { key: 'mainStatus' ,validation:validationToStringOrNull}
];

export {statusesKeys}

export const normalizeKeys = (data: any): any => {
    return {
        id: data.id,
        nameRU: data.nameRu,
        descriptionRU: data.descriptionRu,
        unit: data.unit,
        nameEN: data.nameEn,
        descriptionEN: data.descriptionEn,
        mainStatus: data.main_status
    };
};