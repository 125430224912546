import { createClient } from '@supabase/supabase-js'

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_ROLE_KEY;
export const supabase = createClient(supabaseUrl, supabaseKey);

const urlCache = new Map();

export const getPublicImageUrl = (path) => {
    if (urlCache.has(path)) {
        return urlCache.get(path); // Используем кэш
    }
    const { data, error } = supabase.storage.from('images').getPublicUrl(path);
    if (error) {
        console.error('Error getting public URL:', error.message);
        return null;
    }
    urlCache.set(path, data.publicUrl); // Кэшируем результат
    return data.publicUrl;
};