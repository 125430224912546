import { useQuery, QueryClient } from "react-query";
import { idsKeys, normalizeKeys } from "../constants/idsKeys";
import { supabase } from './supabaseClient';
import { getValidatedDataSupabase } from "../constants/validations";

const CACHE_KEY = 'identities_cache';
const CACHE_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds

const getDataFromCache = () => {
  const cachedData = localStorage.getItem(CACHE_KEY);
  if (cachedData) {
    const { data, timestamp } = JSON.parse(cachedData);
    if (Date.now() - timestamp < CACHE_TIME) {
      return data;
    }
  }
  return null;
};

const setDataToCache = (data: any): void => {
  const cacheData = {
    data,
    timestamp: Date.now(),
  };
  localStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));
};

const useFetchIdsAction = async () => {
  const cachedData = getDataFromCache();
  if (cachedData) {
    return cachedData;
  }

  try {
    const { data: ids, error } = await supabase
    .from('ids_db')
    .select('*')
    .order('rarity', { ascending: true })
    .order('sinner', { ascending: true })
    .order('releaseDate', { ascending: true })
            
    if (error) {
      throw error;
    }

    if (!ids) {
      throw new Error('No data received');
    }

    const result = getValidatedDataSupabase(ids, idsKeys, normalizeKeys);
    setDataToCache(result);
    return result;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const useFetchIds = () => {
  return useQuery("identities", useFetchIdsAction, {
    staleTime: CACHE_TIME,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    retry: 8,
    retryDelay: (attempt) =>
      Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
    onError: (error) => {
      console.error("Error fetching identities:", error);
    },
  });
};

export const createQueryClient = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: CACHE_TIME,
        cacheTime: Infinity,
      },
    },
  });

  if (typeof window !== 'undefined') {
    const cachedData = getDataFromCache();
    if (cachedData) {
      queryClient.setQueryData("identities", cachedData);
    }
  }

  return queryClient;
};