export const locales = [
    {
        locale:"en",
        name:"English",
        isAutoTranslated:false
    },
    // {
    //     locale:"es",
    //     name:"Español",
    //     isAutoTranslated:true
    // },
    // {
    //     locale:"de",
    //     name:"Deutsch",
    //     isAutoTranslated:true
    // },
    // {
    //     locale:"pl",
    //     name:"Polski",
    //     isAutoTranslated:true
    // },
    // {
    //     locale:"ua",
    //     name:"Українська",
    //     isAutoTranslated:true
    // },
    {
        locale:"ru",
        name:"Русский",
        isAutoTranslated:false
    }
    // {
    //     locale:"fr",
    //     name:"Française",
    //     isAutoTranslated:true
    // }
]